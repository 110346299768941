import { defineComponent } from 'vue';
import ResetEmailSubmissionMade from './ResetEmailSubmissionMade.vue';
import ResetEmailForm from './ResetEmailForm.vue';
import componentI18n from '@/i18n/componentI18n';
export default defineComponent({
    name: 'ResetEmail',
    components: { ResetEmailSubmissionMade, ResetEmailForm },
    ...componentI18n('views.auth.reset_email'),
    data() {
        return {
            email: '',
            showForm: true
        };
    },
    methods: {
        setEmail(email) {
            this.showForm = false;
            this.email = email;
        }
    }
});
