import { defineComponent } from 'vue';
import componentI18n from '@/i18n/componentI18n';
import ResetEmailFormButton from './ResetEmailFormButton.vue';
export default defineComponent({
    name: 'ResetEmailSubmissionMade',
    components: {
        ResetEmailFormButton
    },
    ...componentI18n('views.auth.reset_email'),
    props: { email: { type: String, required: true } }
});
