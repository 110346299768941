import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import componentI18n from '@/i18n/componentI18n';
import ResetEmailFormButton from './ResetEmailFormButton.vue';
import { isValidEmail } from '@/helpers/emails';
export default defineComponent({
    name: 'ResetEmailForm',
    components: {
        ResetEmailFormButton
    },
    ...componentI18n('views.auth.reset_email'),
    data() {
        return {
            form: { email: '' },
            emailError: false
        };
    },
    methods: {
        ...mapActions(['sendPasswordResetEmail']),
        setEmail() {
            if (!isValidEmail(this.form.email)) {
                this.emailError = true;
                return;
            }
            this.emailError = false;
            this.sendPasswordResetEmail(this.form.email.toLowerCase().trim());
            this.$emit('set-email', this.form.email.toLowerCase().trim());
        }
    }
});
